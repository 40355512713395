<template>
  <b-modal
    id="modal-chopp-card"
    :title="$t('Selecionar produto de Crédito')"
    no-close-on-backdrop
    hide-footer
    size="lg"
    scrollable
    centered
    @hidden="resetModal"
  >
    <b-container>
      <b-card-actions
        :title="$t('Planos disponíveis')"
        :no-actions="getCreditProducts.length === 0"
        :action-refresh="getCreditProducts.length > 0"
        :show-loading="fetching"
        @refresh="filterData"
      >
        <b-table
          show-empty
          responsive
          striped
          class="bordered"
          :fields="fields"
          :items="getCreditProducts"
        >
          <template #cell(credit)="row">
            <FormulateInput
              v-if="quantityIsEditable(row.item)"
              :id="`credit-${row.index}`"
              v-model="row.item.credit"
              type="text-number"
              currency="R$"
              :precision="2"
              validation="required|min:1.00"
              :validation-messages="{
                required: '*',
                min: '*',
              }"
              @input="calculateQuantity(row.item)"
            />
            <span v-else>{{ getCreditValue(row.item) | currency }}</span>
          </template>

          <template #cell(action)="row">
            <e-button
              variant="primary"
              :icon="'plus'"
              @click="addProduct(row.item)"
            />
          </template>
        </b-table>
      </b-card-actions>
    </b-container>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BTable } from 'bootstrap-vue'
import { formulateHelper } from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EButton from '@/views/components/EButton.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import noImage from '@/assets/images/noimage.png'

export default {
  components: {
    BModal,
    BContainer,
    BTable,
    BCardActions,
    EButton,
  },

  mixins: [formulateHelper],

  data() {
    return {
      fetching: false,
      noImage,
      productWasSelected: false,
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
    ...mapState('pages/pdv/payBox', ['clientForm', 'choppGiftCardData']),
    ...mapGetters('pages/pdv/saleCreditProducts', ['getCreditProducts']),

    fields() {
      return [
        {
          label: this.$t('Nome'),
          key: 'name',
          class: 'text-left',
        },
        {
          label: this.$t('Qtd. (Litros)'),
          key: 'quantity',
          tdClass: 'text-center',
          formatter: (value, index, item) =>
            this.$options.filters.number(this.getCreditQuantity(item), 3, true),
        },
        {
          label: this.$t('Valor do Crédito'),
          key: 'credit',
          tdClass: 'text-right',
          formatter: (value, index, item) =>
            this.$options.filters.currency(this.getCreditValue(item)),
        },
        {
          label: this.$t('Ação'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '120px' },
        },
      ]
    },
  },

  mounted() {},

  methods: {
    ...mapActions('pages/pdv/saleProducts', ['fetchProductByEan']),
    ...mapActions('pages/pdv/saleCreditProducts', [
      'fetchCreditProducts',
      'cleanProductsChoppCard',
    ]),

    filterData() {
      this.getData()
    },

    async getData(isRecharge) {
      try {
        this.fetching = true

        await this.fetchCreditProducts({
          isRecharge,
          isForGiftCard: !!this.choppGiftCardData?.number,
        })
      } catch (error) {
        this.showError({ message: 'Problemas ao buscar os produtos de crédito', error })
      } finally {
        this.fetching = false
      }
    },

    async addProduct(productKit) {
      try {
        const product = await this.fetchProductByEan({
          ean: productKit.ean,
          customerId: this.clientForm.id,
        })
        product.quantity = productKit.quantity

        const creditKitItem = this.getCreditItem(product)
        creditKitItem.quantity = this.getCreditItem(productKit).quantity

        product.price = product.kitItems.reduce(
          (prev, curr) => prev + curr.price * curr.quantity - this.getDiscount(curr),
          0
        )

        this.productWasSelected = true
        this.$emit('add-product', product)

        this.hideModal()
      } catch (error) {
        this.showError({ error })
      }
    },

    getDiscount(kitItem) {
      if (kitItem.unitDiscount === 0) {
        return 0
      }
      if (kitItem.discountType === 'Percentage') {
        return kitItem.price * kitItem.unitDiscount * kitItem.quantity
      }
      if (kitItem.unitDiscount > kitItem.price) {
        return kitItem.price * kitItem.quantity
      }
      return (kitItem.price - kitItem.unitDiscount) * kitItem.quantity
    },

    getCreditItem(productKit) {
      return productKit.kitItems.find(kitItem => kitItem.classification === 'Credit')
    },

    calculateQuantity(productKit) {
      const creditKitItem = this.getCreditItem(productKit)
      creditKitItem.quantity = (productKit.credit || 0) / creditKitItem.price
    },

    getCreditQuantity(productKit) {
      const creditKitItem = this.getCreditItem(productKit)
      return creditKitItem?.quantity
    },

    getCreditValue(productKit) {
      const creditKitItem = this.getCreditItem(productKit)
      return (creditKitItem?.quantity || 0) * creditKitItem.price
    },

    quantityIsEditable(productKit) {
      const creditKitItem = this.getCreditItem(productKit)
      return creditKitItem?.quantityIsEditable && !this.choppGiftCardData.number
    },

    resetModal() {
      if (!this.productWasSelected) {
        this.$emit('cancel')
      }
      this.cleanProductsChoppCard()
    },

    hideModal() {
      this.resetModal()
      this.$bvModal.hide('modal-chopp-card')
    },

    async showModal(isRecharge) {
      try {
        this.$bvModal.show('modal-chopp-card')
        this.getData(isRecharge)
      } catch (error) {
        console.error(error)
        if (error.message) {
          this.showWarning({ message: error.message })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
