var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-advanced-search",
        title: _vm.$t("Pesquisa avançada"),
        "no-close-on-backdrop": "",
        "hide-footer": "",
        size: "lg",
        scrollable: "",
        centered: "",
      },
      on: { hidden: _vm.resetModal },
    },
    [
      _c(
        "b-container",
        [
          _c(
            "e-filters",
            {
              attrs: { searching: _vm.fetching },
              on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "8" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "modalAdvProductName",
                          type: "text",
                          label: _vm.$t("Nome"),
                        },
                        model: {
                          value: _vm.filters.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "name", $$v)
                          },
                          expression: "filters.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "modalAdvProduct-brand",
                          type: "vue-select",
                          label: _vm.$t("Marca"),
                          placeholder: _vm.$t("Selecione uma marca"),
                          options: _vm.getComboBrands,
                        },
                        model: {
                          value: _vm.filters.brand,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "brand", $$v)
                          },
                          expression: "filters.brand",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-actions",
            {
              attrs: {
                title: _vm.$t("Produtos encontrados"),
                "no-actions": _vm.getProducts.length === 0,
                "action-refresh": _vm.getProducts.length > 0,
                "show-loading": _vm.fetching,
              },
              on: { refresh: _vm.filterData },
            },
            [
              _c(
                "div",
                { staticClass: "pb-1" },
                [
                  _c("e-page-size-selector", {
                    attrs: { "per-page": _vm.paging.pageSize },
                    on: { change: _vm.pageSizeChange },
                  }),
                ],
                1
              ),
              _c("b-table", {
                staticClass: "bordered",
                attrs: {
                  "show-empty": "",
                  responsive: "",
                  striped: "",
                  fields: _vm.fields,
                  items: _vm.getProducts,
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(imageSquare)",
                    fn: function (row) {
                      return [
                        _c("b-img", {
                          style: {
                            height: "auto",
                            width: "150px",
                            objectFit: "contain",
                          },
                          attrs: {
                            thumbnail: "",
                            src: row.item.imageSquare || _vm.noImage,
                            alt: "Imagem " + (row.item.name || "sem imagem"),
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(action)",
                    fn: function (row) {
                      return [
                        _c("e-button", {
                          attrs: { variant: "primary", icon: "plus" },
                          on: {
                            click: function ($event) {
                              return _vm.addProduct(row.item)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("e-pagination-summary", {
                        attrs: {
                          "current-page": _vm.paging.currentPage,
                          "per-page": _vm.paging.pageSize,
                          total: _vm.paging.rowCount,
                          "total-on-page": _vm.paging.rowsInCurrentPage,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("b-pagination", {
                        attrs: {
                          align: "right",
                          "total-rows": _vm.paging.rowCount,
                          "per-page": _vm.paging.pageSize,
                          "aria-controls": "client-table",
                        },
                        on: { change: _vm.pageChange },
                        model: {
                          value: _vm.paging.currentPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.paging, "currentPage", $$v)
                          },
                          expression: "paging.currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }