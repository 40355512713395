var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-card-actions",
        { attrs: { "no-actions": "" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  [
                    !_vm.isChoppGiftCardConversion
                      ? _c(
                          "b-row",
                          { staticClass: "method-buttons mb-1" },
                          [
                            _c("b-col", { attrs: { md: "12" } }, [
                              _c("p", { staticClass: "h4" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("Formas de pagamento")) +
                                    " "
                                ),
                              ]),
                            ]),
                            _vm._l(_vm.paymentMethodsInPdv, function (item) {
                              return _c(
                                "b-col",
                                { key: item.id, attrs: { md: "4" } },
                                [
                                  _c("e-button-card", {
                                    attrs: {
                                      id: "tab_payment-method_btn-" + item.id,
                                      "icon-component": "BIcon",
                                      icon: _vm.getIconByPaymentMethod[
                                        item.method
                                      ],
                                      "text-title": item.name,
                                      "text-shortcuts": _vm
                                        .getShortcutByPaymentMethod[item.method]
                                        ? [
                                            _vm.getShortcutByPaymentMethod[
                                              item.method
                                            ],
                                          ]
                                        : [],
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onShowModalValue(item)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c(
                      "b-row",
                      { staticClass: "border-top-dark py-1" },
                      [
                        _c("b-col", [
                          _c("p", { staticClass: "h2 title" }, [
                            _vm._v(" Recebimentos "),
                          ]),
                        ]),
                        _vm.items.length > 0
                          ? _c("b-col", [
                              _c(
                                "p",
                                { staticClass: "h3 title text-right" },
                                [
                                  _vm.paymentForm.balance > 0
                                    ? [
                                        _vm._v(" Saldo a pagar: "),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.paymentForm.balance
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : [
                                        _vm._v(" Troco: "),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.paymentForm.change
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          _vm._l(
                            _vm.paymentForm.receipts,
                            function (receipt, index) {
                              return _c("method-line", {
                                key: index,
                                attrs: {
                                  id: "tab_payment-method_item-" + index,
                                  receipt: receipt,
                                  value: receipt.value,
                                  "is-cancelled": receipt.canceled,
                                  "show-btn-canceled":
                                    _vm.isToShowCancelBtn(receipt),
                                },
                                on: {
                                  "click-delete": function ($event) {
                                    return _vm.onDeleteMethodPayment(index)
                                  },
                                  "click-print": function ($event) {
                                    return _vm.stPrintReceipt({ index: index })
                                  },
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.hasCanceledTefPixPayment
                      ? _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-alert",
                                  {
                                    staticClass: "p-2",
                                    attrs: { show: "", variant: "danger" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "PAY_BOX_SALE.TEF.INSTRUCTION_TEF_CANCEL_PIX"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.hasActiveTefCardPayment
                      ? _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-alert",
                                  {
                                    staticClass: "p-2",
                                    attrs: { show: "", variant: "danger" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "PAY_BOX_SALE.TEF.INSTRUCTION_TEF_CANCEL"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-value", {
        ref: "modalValue",
        attrs: {
          "sale-uuid": _vm.currentSaleUuid,
          "balance-value": _vm.paymentForm.balance,
          "btn-confirm-busy": _vm.waitPeripheral,
        },
        on: { "click-confirm": _vm.onAddMethodPayment },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }