import { render, staticRenderFns } from "./ModalDiscount.vue?vue&type=template&id=5e4b4bb0&scoped=true&"
import script from "./ModalDiscount.vue?vue&type=script&lang=js&"
export * from "./ModalDiscount.vue?vue&type=script&lang=js&"
import style0 from "./ModalDiscount.vue?vue&type=style&index=0&id=5e4b4bb0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e4b4bb0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/echope/echope-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5e4b4bb0')) {
      api.createRecord('5e4b4bb0', component.options)
    } else {
      api.reload('5e4b4bb0', component.options)
    }
    module.hot.accept("./ModalDiscount.vue?vue&type=template&id=5e4b4bb0&scoped=true&", function () {
      api.rerender('5e4b4bb0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/pdv/pay-box/components/tabs/components/ModalDiscount.vue"
export default component.exports