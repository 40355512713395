<template>
  <b-modal
    id="modal-chopp-card-balance"
    :title="$t('Consultar saldo do cartão')"
    no-close-on-backdrop
    hide-footer
    size="lg"
    scrollable
    centered
    @hidden="resetModal"
  >
    <b-card-actions
      no-actions
      :show-loading="fetching"
    >
      <b-row>
        <b-col md="12">
          <FormulateInput
            id="customer"
            :value="customerName"
            type="label"
            :label="$t('Cliente')"
            filter="uppercase"
          />
        </b-col>
        <b-col md="12">
          <FormulateInput
            id="customer"
            :value="totalSpent"
            type="label"
            :label="$t('Créditos adquiridos')"
            filter="currency"
          />
        </b-col>
        <b-col md="12">
          <FormulateInput
            id="customer"
            :value="balance"
            type="label"
            style="font-size: 30px"
            :label="$t('Saldo atual')"
            filter="currency"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <b-row class="mt-1">
      <b-col class="d-flex justify-content-end">
        <e-button
          variant="primary"
          type="button"
          :text="$t('Ok')"
          @click="hideModal()"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from 'bootstrap-vue'
import { formulateHelper } from '@/mixins'
import { mapGetters } from 'vuex'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EButton from '@/views/components/EButton.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCardActions,
    EButton,
  },

  mixins: [formulateHelper],

  data() {
    return {
      fetching: false,
      cardNumber: null,
      customer: null,
    }
  },

  computed: {
    ...mapGetters('pages/pdv/payBoxConfiguration', ['currentPayboxConfiguration']),
    storeId() {
      const { store } = this.currentPayboxConfiguration
      return store?.id
    },
    customerName() {
      return this.customer?.customer?.name
    },
    totalSpent() {
      return this.customer?.totalSpent
    },
    balance() {
      return this.customer?.balance
    },
  },

  mounted() {},

  methods: {
    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          const { data } = await this.$http.get(
            `/api/palmbeer/stores/${this.storeId}/clients/by-card-number/${this.cardNumber}/balance`
          )
          this.customer = data
        } catch (error) {
          this.showError({ message: 'Problemas ao buscar os dados', error })
          this.hideModal()
        } finally {
          this.fetching = false
        }
      })
    },

    resetModal() {
      this.cardNumber = null
      this.customer = null
    },

    hideModal() {
      this.resetModal()
      this.$bvModal.hide('modal-chopp-card-balance')
    },

    async showModal(cardNumber) {
      try {
        this.$bvModal.show('modal-chopp-card-balance')
        this.cardNumber = cardNumber
        await this.getData()
      } catch (error) {
        if (error.message) {
          this.showError({ error })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
