var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "paybox-content-container" }, [
    _c(
      "section",
      { staticClass: "left-side" },
      [
        _c("pay-box-purchase-info", {
          ref: "payBoxPurchaseInfo",
          attrs: {
            "get-height": _vm.getHeight,
            "tab-height": _vm.windowSize.tabHeight,
          },
        }),
      ],
      1
    ),
    _c(
      "section",
      { staticClass: "right-side" },
      [_c("pay-box-items-list", { attrs: { "get-height": _vm.getHeight } })],
      1
    ),
    _vm.needCashUp
      ? _c("section", { staticClass: "block-pdv" }, [
          _c(
            "div",
            { staticClass: "content-center" },
            [
              _c("b-card-actions", { attrs: { "no-actions": "" } }, [
                _c("div", [
                  _c("p", { staticClass: "h3 text-danger" }, [
                    _vm._v(" Caixa Bloqueado "),
                  ]),
                ]),
                _c("div", [
                  _c("p", [
                    _vm._v(" Valor no caixa ultrapassou o limite permitido. "),
                    _c("br"),
                    _c("b", [
                      _vm._v(
                        "Por favor, realize a sangria para liberar o caixa"
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }