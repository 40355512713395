var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal_cold_option",
        title: _vm.$t("Estado dos produtos"),
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
        "hide-footer": "",
        scrollable: "",
        centered: "",
        size: "lg",
      },
      on: { hidden: _vm.resetModal },
    },
    [
      _c(
        "FormulateForm",
        {
          ref: "formReceiveValue",
          attrs: { name: "formReceiveValue" },
          on: { submit: _vm.onConfirm },
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-center" },
                    [
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: {
                          id: "modal_cold_option-btn_cold_on_ice",
                          variant: "primary",
                          text: _vm.$t("Embalado no gelo"),
                          "text-shortcuts": ["CTRL", "1"],
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onConfirm("ColdOnIce")
                          },
                        },
                      }),
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: {
                          id: "modal_cold_option-btn_cold",
                          variant: "primary",
                          text: _vm.$t("Gelado"),
                          "text-shortcuts": ["CTRL", "2"],
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onConfirm("Cold")
                          },
                        },
                      }),
                      _c("e-button", {
                        attrs: {
                          id: "modal_cold_option-btn_natural",
                          variant: "primary",
                          text: _vm.$t("Natural"),
                          "text-shortcuts": ["CTRL", "3"],
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onConfirm("Natural")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }