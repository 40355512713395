<template>
  <b-modal
    id="modal-cardNumber"
    :title="$t('Informe o número do Cartão de Presente')"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    centered
    @hidden="resetModal"
  >
    <FormulateForm
      ref="formGiftCard"
      name="formGiftCard"
      @submit="onConfirm"
    >
      <b-container>
        <b-row>
          <b-col>
            <div class="d-flex align-items-center">
              <FormulateInput
                id="modalCardNumber"
                v-model="form.cardNumber"
                name="cardNumber"
                class="mr-1 input-size"
                type="text-number"
                :precision="0"
                :label="$t('Número do Cartão')"
                validation="required|min:0"
              />
              <!-- placement: tooltipPosition, -->
              <!-- <b-button
                id="modal_cardNumber-btn_reset_value"
                variant="danger"
                class="p-1"
                :title="$t('Resetar desconto')"
                @click="onResetValue"
              >
                <feather-icon
                  icon="DeleteIcon"
                  size="20"
                />
              </b-button> -->
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col class="d-flex justify-content-end">
            <b-button
              id="modal_cardNumber-btn_cancel"
              class="mr-1"
              variant="danger"
              @click="hideModal"
            >
              Cancelar <br>
              [ESC]
            </b-button>
            <e-button
              id="modal_cardNumber-btn_confirm"
              variant="primary"
              type="submit"
              :busy="busy"
              :text="$t('Confirmar')"
            />
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol, BButton } from 'bootstrap-vue'
// import { mapState } from 'vuex'
import { formulateHelper } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import { EButton } from '@/views/components'
// import delegablePermissions from '@/utils/delegable-permissions'

const getInitialForm = () => ({
  cardNumber: 0,
  delegateUserData: null,
})

const giftCardOperationEnum = {
  Sell: 'Sell',
  Convert: 'Convert',
}

export default {
  components: { BModal, BContainer, BRow, BCol, BButton, EButton },

  mixins: [formulateHelper],

  props: {
    initialValue: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      busy: false,
      operation: giftCardOperationEnum.Sell,
      form: getInitialForm(),
    }
  },

  computed: {
    ...mapState('pages/pdv/payBox', ['items']),
  },

  methods: {
    ...mapActions('pages/pdv/payBox', [
      'searchChoppGiftCardForSell',
      'searchChoppGiftCardForConversion',
    ]),
    async onConfirm() {
      try {
        this.busy = true
        if (this.operation === giftCardOperationEnum.Sell) {
          const existingNumber = this.items.find(
            item => item.choppGiftCardNumber === this.form.cardNumber
          )
          if (existingNumber) {
            this.showError({
              title: this.$t('Cartão já adicionado'),
              message: this.$t('Este número de cartão já foi adicionado na compra'),
            })
          } else {
            const result = await this.searchChoppGiftCardForSell({
              cardNumber: this.form.cardNumber,
            })
            this.$emit('click-confirm', {
              operation: this.operation,
              id: result.id,
              number: this.form.cardNumber,
              value: result.value,
              ean: result.ean,
            })
            this.hideModal()
          }
        } else {
          const result = await this.searchChoppGiftCardForConversion({
            cardNumber: this.form.cardNumber,
          })
          this.$emit('click-confirm', {
            operation: this.operation,
            id: result.id,
            number: this.form.cardNumber,
            value: result.value,
            ean: result.ean,
          })
          this.hideModal()
        }
      } catch (error) {
        if (error.response?.status !== 404) {
          this.showError({ error })
        }
        this.showError({
          title: this.$t('Cartão não disponível'),
          message: this.$t('O número informado não está disponível'),
        })
      } finally {
        this.busy = false
      }
    },

    onResetValue() {
      this.form.cardNumber = 0
      this.$formulate.resetValidation('formGiftCard')
    },

    resetModal() {
      this.form = getInitialForm()
    },

    hideModal() {
      this.$bvModal.hide('modal-cardNumber')
    },

    /// operation: see giftCardOperationEnum in this file
    async showModal(operation) {
      try {
        this.operation = operation
        this.$bvModal.show('modal-cardNumber')
        this.form.cardNumber = this.initialValue

        this.$nextTick(() => {
          this.focusInputWithTimeout('#modalCardNumber')
        })
      } catch (error) {
        /* eslint-disable no-console */
        console.error(error)
        if (error.message) {
          this.showWarning({ message: error.message })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-20px);
}

.input-size {
  font-size: 1.4rem;
}
</style>
