var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-discount",
        title: _vm.$t("Aplicar Desconto na compra"),
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        "hide-footer": "",
        centered: "",
      },
      on: { hidden: _vm.resetModal },
    },
    [
      _c(
        "FormulateForm",
        {
          ref: "formDiscount",
          attrs: { name: "formDiscount" },
          on: { submit: _vm.onConfirm },
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c("FormulateInput", {
                          staticClass: "mr-1 input-size",
                          attrs: {
                            id: "modalInputDiscount",
                            name: "discount",
                            type: "text-number",
                            currency: "R$",
                            precision: 2,
                            label: _vm.$t("Valor do desconto"),
                            validation: "required|min:0",
                          },
                          model: {
                            value: _vm.form.discount,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "discount", $$v)
                            },
                            expression: "form.discount",
                          },
                        }),
                        _c(
                          "b-button",
                          {
                            staticClass: "p-1",
                            attrs: {
                              id: "modal_discount-btn_reset_value",
                              variant: "danger",
                              title: _vm.$t("Resetar desconto"),
                            },
                            on: { click: _vm.onResetValue },
                          },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "DeleteIcon", size: "20" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            id: "modal_discount-btn_cancel",
                            variant: "danger",
                          },
                          on: { click: _vm.hideModal },
                        },
                        [_vm._v(" Cancelar "), _c("br"), _vm._v(" [ESC] ")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            id: "modal_discount-btn_confirm",
                            variant: "primary",
                            type: "submit",
                          },
                        },
                        [_vm._v(" Confirmar ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }