<template>
  <b-modal
    id="modal_delivery-address"
    :title="$t('Endereço para entrega')"
    no-close-on-backdrop
    hide-header-close
    size="lg"
    scrollable
    centered
    @hidden="resetModal"
  >
    <FormulateForm
      ref="formDeliveryAddress"
      name="formDeliveryAddress"
    >
      <b-container>
        <b-card-actions
          :title="$t('Endereços do cliente')"
          no-actions
        >
          <FormulateInput
            id="modal_delivery-address_options"
            v-model="modalForm.addressSelected"
            type="radio"
            :aria-label="$t('Endereços do cliente')"
            :options="addressOptions"
            @input="changeAddress"
          />
        </b-card-actions>

        <e-address
          ref="addressComponent"
          v-model="modalForm.address"
          @after-find-zipcode="onFetchDeliveryTax"
        />

        <b-card-actions
          :title="$t('Observação da entrega')"
          no-actions
        >
          <FormulateInput
            id="modal_delivery-address_observation"
            v-model="modalForm.observation"
            type="textarea"
            :rows="4"
            :aria-label="$t('Observação')"
          />
        </b-card-actions>
      </b-container>
    </FormulateForm>

    <template #modal-footer>
      <b-row class="w-100 d-flex justify-content-between align-items-center">
        <b-col>
          <p class="mb-0 h3">
            {{ $t('Valor da taxa') }}
          </p>
          <p class="mb-0 h3">
            {{ modalForm.deliveryTax | currency }}
          </p>
        </b-col>

        <b-col class="d-flex justify-content-end">
          <e-button
            id="modal_delivery-btn_cancel"
            class="mr-1"
            variant="outline-primary"
            :text="$t('Cancelar')"
            :text-shortcuts="['ESC']"
            @click="hideModal"
          />

          <e-button
            id="modal_delivery-btn_confirm"
            variant="primary"
            :text="$t('Confirmar')"
            @click="onConfirm"
          />
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { formulateHelper } from '@/mixins'
import _ from 'lodash'
import EButton from '@/views/components/EButton.vue'
import EAddress from '@/views/components/EAddress.vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { getInitialDeliveryForm } from '@/store/pages/pdv/pay-box'

export default {
  components: { BModal, BContainer, BRow, BCol, EButton, EAddress, BCardActions },

  mixins: [formulateHelper],

  props: {
    deliveryForm: {
      type: Object,
      required: true,
    },
    addressList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modalForm: getInitialDeliveryForm(),
      loadingAddressForm: false,
    }
  },

  computed: {
    addressOptions() {
      const existingAddresses = {}

      this.addressList.forEach(ad => {
        existingAddresses[
          ad.id
        ] = `${ad.publicPlace}, Nº ${ad.number}, ${ad.neighborhood}, ${ad.city}/${ad.province}`
      })

      return {
        ...existingAddresses,
        other: this.$t('Outro'),
      }
    },
  },

  methods: {
    ...mapActions('pages/pdv/payBox', ['fetchDeliveryTax']),

    onConfirm() {
      if (!this.$refs.addressComponent.validate()) return

      this.$emit('click-confirm', this.modalForm)
      this.hideModal()
    },

    async onFetchDeliveryTax() {
      // TODO buscar na API valor do frete
      const deliveryTax = await this.fetchDeliveryTax({ addressData: this.modalForm.address })
      this.modalForm.deliveryTax = deliveryTax
    },

    resetModal() {
      this.modalForm = getInitialDeliveryForm()
    },

    hideModal() {
      this.$bvModal.hide('modal_delivery-address')
    },
    async showModal() {
      try {
        if (!this.deliveryForm.addressSelected) {
          if (this.addressList.length > 0) {
            const addressSuggestion = this.addressList[0]

            this.modalForm = {
              ...getInitialDeliveryForm(),
              addressSelected: String(addressSuggestion.id),
              address: addressSuggestion,
            }

            await this.onFetchDeliveryTax()
          }
        } else {
          this.modalForm = {
            ...getInitialDeliveryForm(),
            ...this.deliveryForm,
          }
        }

        this.$bvModal.show('modal_delivery-address')
      } catch (error) {
        this.showError({ error })
      }
    },

    // eslint-disable-next-line func-names
    changeAddress: _.debounce(function (value) {
      if (value === 'other') {
        this.resetModal()
        this.focusInput('#address-zipcode')
        return
      }

      const data = this.addressList.find(ad => String(ad.id) === value)
      this.modalForm = {
        ...this.modalForm,
        address: data,
      }
      this.onFetchDeliveryTax()
    }, 500),
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-20px);
}

.input-size {
  font-size: 1.4rem;
}
</style>
