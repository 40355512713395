<template>
  <b-modal
    id="modal-discount"
    :title="$t('Aplicar Desconto na compra')"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    centered
    @hidden="resetModal"
  >
    <FormulateForm
      ref="formDiscount"
      name="formDiscount"
      @submit="onConfirm"
    >
      <b-container>
        <b-row>
          <b-col>
            <div class="d-flex align-items-center">
              <FormulateInput
                id="modalInputDiscount"
                v-model="form.discount"
                name="discount"
                class="mr-1 input-size"
                type="text-number"
                currency="R$"
                :precision="2"
                :label="$t('Valor do desconto')"
                validation="required|min:0"
              />
              <!-- placement: tooltipPosition, -->
              <b-button
                id="modal_discount-btn_reset_value"
                variant="danger"
                class="p-1"
                :title="$t('Resetar desconto')"
                @click="onResetValue"
              >
                <feather-icon
                  icon="DeleteIcon"
                  size="20"
                />
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col class="d-flex justify-content-end">
            <b-button
              id="modal_discount-btn_cancel"
              class="mr-1"
              variant="danger"
              @click="hideModal"
            >
              Cancelar <br>
              [ESC]
            </b-button>
            <b-button
              id="modal_discount-btn_confirm"
              variant="primary"
              type="submit"
            >
              Confirmar
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol, BButton } from 'bootstrap-vue'
import { mapState } from 'vuex'
import { formulateHelper } from '@/mixins'
import delegablePermissions from '@/utils/delegable-permissions'

const getInitialForm = () => ({
  discount: 0,
  delegateUserData: null,
})

export default {
  components: { BModal, BContainer, BRow, BCol, BButton },

  mixins: [formulateHelper],

  props: {
    initialValue: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      form: getInitialForm(),
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
  },

  methods: {
    onConfirm() {
      this.$emit('click-confirm', this.form)
      this.hideModal()
    },

    onResetValue() {
      this.form.discount = 0
      this.$formulate.resetValidation('formDiscount')
    },
    resetModal() {
      this.form = getInitialForm()
      this.delegateUserData = null
    },

    hideModal() {
      this.$bvModal.hide('modal-discount')
    },
    async showModal() {
      try {
        this.delegateUserData = await this.modalAuthRef.auth(delegablePermissions.PDV_DISCOUNT)
        this.$bvModal.show('modal-discount')
        this.form.discount = this.initialValue

        this.$nextTick(() => {
          this.focusInput('#modalInputDiscount')
        })
      } catch (error) {
        /* eslint-disable no-console */
        console.error(error)
        if (error.message) {
          this.showWarning({ message: error.message })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-20px);
}

.input-size {
  font-size: 1.4rem;
}
</style>
