var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("layout-pay-box-section", {
        attrs: { "with-header": false },
        scopedSlots: _vm._u([
          {
            key: "main",
            fn: function () {
              return [
                _c(
                  "b-table-simple",
                  {
                    staticClass: "paybox-items-table font-weight-bold mb-0",
                    style: {
                      minHeight:
                        _vm.getHeight +
                        14 -
                        _vm.deliveryAddressBoxInfoHeight +
                        "px",
                    },
                    attrs: {
                      borderless: "",
                      "sticky-header": "",
                      "show-empty": "",
                    },
                  },
                  [
                    _c(
                      "b-thead",
                      { staticClass: "paybox-items-thead" },
                      [
                        _c(
                          "b-tr",
                          [
                            _vm._l(_vm.fields, function (field) {
                              return [
                                _c(
                                  "b-th",
                                  {
                                    key: field.key,
                                    staticClass: "paybox-items-th",
                                    class: field.class,
                                    style: field.thStyle,
                                  },
                                  [_vm._v(" " + _vm._s(field.label) + " ")]
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-tbody",
                      { staticClass: "paybox-items-tbody" },
                      [
                        _vm.items.length === 0
                          ? _c(
                              "b-tr",
                              [
                                _c(
                                  "b-td",
                                  {
                                    staticClass: "text-center my-auto",
                                    attrs: { colspan: "6" },
                                  },
                                  [
                                    _c("div", [
                                      _c("p", { staticClass: "h4" }, [
                                        _vm._v(" Nenhum Produto "),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._l(_vm.getItems, function (item, index) {
                              return [
                                _c(
                                  "b-tr",
                                  { key: index, staticClass: "row-item" },
                                  [
                                    _c("b-td", { staticClass: "text-center" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.product.kit
                                              ? ""
                                              : _vm.getPositionItem(index)
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("b-td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            "" +
                                              item.product.name +
                                              (item.choppGiftCardNumber
                                                ? " (" +
                                                  item.choppGiftCardNumber +
                                                  ")"
                                                : "")
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c(
                                      "b-td",
                                      {
                                        staticClass: "text-center edit-cell",
                                        attrs: {
                                          title:
                                            !_vm.isChoppGiftCardConversion &&
                                            !item.choppGiftCardNumber
                                              ? _vm.$t(
                                                  "Duplo clique para alterar"
                                                )
                                              : "",
                                        },
                                        on: {
                                          dblclick: function ($event) {
                                            return _vm.showEditAmountCell(
                                              index,
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        !_vm.itemReadOnly &&
                                        index === _vm.indexUpdate
                                          ? _c(
                                              "FormulateForm",
                                              {
                                                on: {
                                                  submit: function ($event) {
                                                    return _vm.updateItem(item)
                                                  },
                                                },
                                              },
                                              [
                                                _c("FormulateInput", {
                                                  attrs: {
                                                    id:
                                                      "paybox-item-list-amount-" +
                                                      index,
                                                    name: "QTDE.",
                                                    type: "text-number",
                                                    validation:
                                                      "required|min:1",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formUpdate.amount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formUpdate,
                                                        "amount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formUpdate.amount",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                " " + _vm._s(item.amount) + " "
                                              ),
                                            ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-td",
                                      { staticClass: "text-left" },
                                      [
                                        item.discount > 0 ||
                                        item.product.unitDiscount > 0 ||
                                        item.discountPriceTable > 0
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "paybox-items-canceled-price",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          item.unitValue ||
                                                            item.product.price,
                                                          null,
                                                          2
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        item.priceInfo
                                                          .priceWithDiscount,
                                                        null,
                                                        2
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          : item.promotionId &&
                                            (item.product.priceFrom > 0 ||
                                              item.product.originalPrice > 0)
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "paybox-items-canceled-price",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          item.product
                                                            .priceFrom ||
                                                            item.product
                                                              .originalPrice,
                                                          null,
                                                          2
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        item.priceInfo
                                                          .priceWithDiscount,
                                                        null,
                                                        2
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          : [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        item.unitValue ||
                                                          item.product.price,
                                                        null,
                                                        2
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                      ],
                                      2
                                    ),
                                    _c("b-td", { staticClass: "text-left" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              item.subtotal,
                                              null,
                                              2
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    !_vm.itemReadOnly
                                      ? _c("b-td", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center justify-content-center",
                                              staticStyle: { gap: "10px" },
                                            },
                                            [
                                              index !== _vm.indexUpdate
                                                ? [
                                                    !_vm.isChoppGiftCardConversion
                                                      ? _c("e-button", {
                                                          attrs: {
                                                            id:
                                                              "paybox-item-list-delete-" +
                                                              index,
                                                            tabindex: "-1",
                                                            size: "sm",
                                                            variant:
                                                              _vm.getButtonVariant(
                                                                item
                                                              ),
                                                            icon: "trash",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeItem(
                                                                index,
                                                                item
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                : [
                                                    _c("e-button", {
                                                      attrs: {
                                                        id:
                                                          "paybox-item-list-amount-confirm-update-" +
                                                          index,
                                                        title: "Confirmar",
                                                        size: "sm",
                                                        variant: "primary",
                                                        icon: "check-circle",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.updateItem(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("e-button", {
                                                      attrs: {
                                                        id:
                                                          "paybox-item-list-amount-cancel-update-" +
                                                          index,
                                                        title: "Cancelar",
                                                        size: "sm",
                                                        variant: "danger",
                                                        icon: "x-circle",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.cleanUpdate()
                                                        },
                                                      },
                                                    }),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                item.product.kit
                                  ? _vm._l(
                                      _vm.getKitItems(item.product.kitItems),
                                      function (kitItem, kitIndex) {
                                        return _c(
                                          "b-tr",
                                          {
                                            key: index + "_" + kitIndex,
                                            staticClass: "row-item",
                                          },
                                          [
                                            _c(
                                              "b-td",
                                              { staticClass: "text-center" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getPositionItem(
                                                        index
                                                      ) +
                                                        (_vm.getKitItems(
                                                          item.product.kitItems
                                                        ).length -
                                                          1) -
                                                        kitIndex
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-td",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                                staticStyle: {
                                                  "padding-left": "30px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "mr-1 mb-0" },
                                                  [
                                                    _c("b-icon", {
                                                      attrs: {
                                                        icon: "arrow-return-right",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c("p", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        kitItem.product.name
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "b-td",
                                              { staticClass: "text-center" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(kitItem.amount) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-td",
                                              { staticClass: "text-left" },
                                              [
                                                kitItem.discount > 0 ||
                                                kitItem.product.unitDiscount > 0
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "paybox-items-canceled-price",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currency"
                                                                )(
                                                                  kitItem.unitValue ||
                                                                    kitItem
                                                                      .product
                                                                      .price,
                                                                  null,
                                                                  2
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "currency"
                                                              )(
                                                                kitItem
                                                                  .priceInfo
                                                                  .priceWithDiscount,
                                                                null,
                                                                2
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  : [
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "currency"
                                                              )(
                                                                kitItem.unitValue ||
                                                                  kitItem
                                                                    .product
                                                                    .price,
                                                                null,
                                                                2
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ],
                                              ],
                                              2
                                            ),
                                            _c("b-td"),
                                            !_vm.itemReadOnly
                                              ? _c("b-td")
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  : _vm._e(),
                                item.product.lendingItemAssociated
                                  ? _vm._l(
                                      item.product.lendingItemAssociated,
                                      function (lendingItem, lendingIndex) {
                                        return _c(
                                          "b-tr",
                                          {
                                            key:
                                              index +
                                              "_" +
                                              lendingIndex +
                                              "_" +
                                              lendingItem.id,
                                            staticClass: "row-item",
                                          },
                                          [
                                            _c("b-td"),
                                            _c(
                                              "b-td",
                                              {
                                                staticStyle: {
                                                  "padding-left": "30px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      lendingItem.product.name
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-td",
                                              { staticClass: "text-center" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(lendingItem.amount) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-td",
                                              { staticClass: "text-left" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("currency")(0)
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("b-td"),
                                            !_vm.itemReadOnly
                                              ? _c("b-td")
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  : _vm._e(),
                              ]
                            }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm.isDelivery
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "bg-white w-100 d-flex align-items-center justify-content-start border-top-dark",
                        style:
                          "height: " + _vm.deliveryAddressBoxInfoHeight + "px",
                      },
                      [
                        false
                          ? _c(
                              "div",
                              { staticClass: "mx-1" },
                              [
                                _c("e-button-icon", {
                                  staticStyle: { padding: "5px" },
                                  attrs: { variant: "danger", icon: "XIcon" },
                                  on: { click: _vm.onCancelDelivery },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "mx-1" },
                          [
                            _c("feather-icon", {
                              staticClass: "text-primary",
                              attrs: { icon: "TruckIcon", size: "30" },
                            }),
                          ],
                          1
                        ),
                        _c("div", [
                          _c("p", { staticClass: "mb-0 font-weight-bold" }, [
                            _vm._v(" Endereço para entrega: "),
                            _c("br"),
                            _vm._v(" " + _vm._s(_vm.deliveryAddressText) + " "),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "b-row",
                  { staticClass: "h-100 d-flex align-items-center" },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center",
                        attrs: { md: "12" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex align-items-baseline",
                            staticStyle: { gap: "10px" },
                          },
                          [
                            _c("div", { staticClass: "footer-value-info" }, [
                              _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass: "h5 text-right mb-0 text-dark",
                                  },
                                  [_vm._v(" Subtotal ")]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-end",
                                  staticStyle: { gap: "10px" },
                                },
                                [
                                  !_vm.itemReadOnly
                                    ? _c("e-button-icon", {
                                        staticStyle: { padding: "5px" },
                                        attrs: {
                                          id: "paybox-item_list-btn_show_discount",
                                          pill: "",
                                          title: _vm.$t("Adicionar desconto"),
                                          variant: "dark",
                                          icon: "PlusIcon",
                                        },
                                        on: { click: _vm.onShowModalDiscount },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "h5 text-right mb-0 text-dark",
                                    },
                                    [_vm._v(" Descontos ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "footer-value-info" }, [
                              _c(
                                "p",
                                { staticClass: "h3 text-left mb-0 text-dark" },
                                [
                                  _c("span", {}, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.getSubtotalSaleWithoutDiscountInfo,
                                            null,
                                            2
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "p",
                                { staticClass: "h3 text-left mb-0 text-dark" },
                                [
                                  _c("span", {}, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.getTotalItemsDiscountInfo,
                                            null,
                                            2
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm.isDelivery
                          ? _c("div", [
                              _c(
                                "p",
                                { staticClass: "h3 text-left mb-0 text-dark" },
                                [
                                  _vm._v(" Frete "),
                                  _c("br"),
                                  _c("span", { staticClass: "h3 text-dark" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.deliveryForm.deliveryTax,
                                            null,
                                            2
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.showComission
                          ? _c("div", [
                              _c("div", { staticClass: "footer-value-info" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-content-end",
                                    staticStyle: { gap: "10px" },
                                  },
                                  [
                                    !_vm.itemReadOnly &&
                                    _vm.hasWaiterComissionConfig
                                      ? _c("e-button-icon", {
                                          staticStyle: { padding: "5px" },
                                          attrs: {
                                            id: "paybox-item_list-btn_add_comission",
                                            pill: "",
                                            title: _vm.$t("Adicionar comissão"),
                                            variant: "dark",
                                            icon: "PlusIcon",
                                          },
                                          on: {
                                            click: _vm.onShowModalComission,
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "h5 text-right mb-0 text-dark",
                                      },
                                      [_vm._v(" Comissão ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "footer-value-info" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass: "h3 text-left mb-0 text-dark",
                                  },
                                  [
                                    _c("span", {}, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.comission || 0,
                                              null,
                                              2
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", [
                          _c(
                            "p",
                            { staticClass: "h2 text-left mb-0 text-dark" },
                            [
                              _vm._v(" Total "),
                              _c("br"),
                              _c("span", { staticClass: "h1 text-dark" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.getTotalSale,
                                        null,
                                        2
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("modal-discount", {
        ref: "modalDiscount",
        attrs: { "initial-value": _vm.paymentForm.discount },
        on: { "click-confirm": _vm.onAddDiscount },
      }),
      _c("modal-comission", {
        ref: "modalComission",
        on: { "click-confirm": _vm.onAddComission },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }