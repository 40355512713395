var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("layout-pay-box-section", {
        attrs: { "with-header": false },
        scopedSlots: _vm._u([
          {
            key: "main",
            fn: function () {
              return [
                _c(
                  "b-tabs",
                  {
                    attrs: { id: "purchaseInfoTab", fill: "" },
                    model: {
                      value: _vm.actualTab,
                      callback: function ($$v) {
                        _vm.actualTab = $$v
                      },
                      expression: "actualTab",
                    },
                  },
                  [
                    _c(
                      "b-tab",
                      {
                        staticClass: "content-tab",
                        style: { height: _vm.getHeightTabContent + "px" },
                        attrs: {
                          id: "purchase-tab-product",
                          disabled: _vm.isConsignedSaleFinish,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "p",
                                  { staticClass: "m-0" },
                                  [
                                    _vm._v(" PRODUTO "),
                                    _c("e-shortcut", {
                                      attrs: { shortcuts: ["F6"] },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [_c("product-tab", { ref: "productTab" })],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        staticClass: "content-tab",
                        style: { height: _vm.getHeightTabContent + "px" },
                        attrs: { id: "purchase-tab-client" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "p",
                                  { staticClass: "m-0" },
                                  [
                                    _vm._v(" CLIENTE "),
                                    _c("e-shortcut", {
                                      attrs: { shortcuts: ["F7"] },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [_c("client-tab", { ref: "clientTab" })],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        staticClass: "content-tab",
                        style: { height: _vm.getHeightTabContent + "px" },
                        attrs: { id: "purchase-tab-payment" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "p",
                                  { staticClass: "m-0 text-upper" },
                                  [
                                    _vm._v(" PAGAMENTO "),
                                    _c("e-shortcut", {
                                      attrs: { shortcuts: ["F10"] },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [_c("payment-tab", { ref: "paymentTab" })],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "b-row",
                  { staticClass: "h-100 d-flex align-items-center" },
                  [
                    _c(
                      "b-col",
                      [
                        _c("e-button", {
                          attrs: {
                            id: "paybox_purchase-btn_cancel",
                            busy: _vm.isFinishingSale,
                            variant: "danger",
                            text: _vm.isExistingSale
                              ? _vm.$t("SAIR")
                              : _vm.$t("CANCELAR"),
                            icon: "x-circle",
                            size: "sm",
                            tabindex: "-1",
                            block: "",
                            "text-shortcuts": ["F5"],
                          },
                          on: { click: _vm.onCancel },
                        }),
                      ],
                      1
                    ),
                    false
                      ? _c(
                          "b-col",
                          [
                            _c("e-button", {
                              attrs: {
                                id: "paybox_purchase-btn_delivery",
                                busy: _vm.isFinishingSale,
                                variant: "dark",
                                text: _vm.$t("ENTREGA"),
                                icon: "truck",
                                size: "sm",
                                block: "",
                                "text-shortcuts": [],
                              },
                              on: { click: _vm.onShowDeliveryAddress },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isConsignedSaleFinish
                      ? _c(
                          "b-col",
                          [
                            _c("e-button", {
                              attrs: {
                                id: "paybox_purchase-btn_items_return",
                                busy: _vm.isFinishingSale,
                                variant: "dark",
                                text: _vm.$t("DEVOLUÇÕES"),
                                icon: "arrow-clockwise",
                                size: "sm",
                                block: "",
                              },
                              on: { click: _vm.onShowReturnItems },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      [
                        _c("e-button", {
                          attrs: {
                            id: "paybox_purchase-btn_finish",
                            busy: _vm.isFinishingSale,
                            variant: "primary",
                            text:
                              _vm.tabEnum.payment === _vm.actualTab
                                ? _vm.getFinishLabel
                                : _vm.$t("PAGAMENTO"),
                            icon: "check-circle",
                            size: "sm",
                            block: "",
                            "text-shortcuts": ["F10"],
                          },
                          on: { click: _vm.onFinish },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("modal-return-items", {
        ref: "modalReturnItems",
        attrs: {
          "show-products": "",
          "return-form": _vm.returnForm,
          "sale-data": _vm.getConsignedReturnData,
        },
        on: { confirm: _vm.onConfimReturnForm },
      }),
      false
        ? _c("modal-delivery-address", {
            ref: "modalDeliveryAddress",
            attrs: {
              "delivery-form": _vm.deliveryForm,
              "address-list": _vm.clientForm.addressList,
            },
            on: { "click-confirm": _vm.onConfirmDeliveryAddress },
          })
        : _vm._e(),
      _c("modal-cold-option", { ref: "modalColdOption" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }