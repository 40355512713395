var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-chopp-card",
        title: _vm.$t("Selecionar produto de Crédito"),
        "no-close-on-backdrop": "",
        "hide-footer": "",
        size: "lg",
        scrollable: "",
        centered: "",
      },
      on: { hidden: _vm.resetModal },
    },
    [
      _c(
        "b-container",
        [
          _c(
            "b-card-actions",
            {
              attrs: {
                title: _vm.$t("Planos disponíveis"),
                "no-actions": _vm.getCreditProducts.length === 0,
                "action-refresh": _vm.getCreditProducts.length > 0,
                "show-loading": _vm.fetching,
              },
              on: { refresh: _vm.filterData },
            },
            [
              _c("b-table", {
                staticClass: "bordered",
                attrs: {
                  "show-empty": "",
                  responsive: "",
                  striped: "",
                  fields: _vm.fields,
                  items: _vm.getCreditProducts,
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(credit)",
                    fn: function (row) {
                      return [
                        _vm.quantityIsEditable(row.item)
                          ? _c("FormulateInput", {
                              attrs: {
                                id: "credit-" + row.index,
                                type: "text-number",
                                currency: "R$",
                                precision: 2,
                                validation: "required|min:1.00",
                                "validation-messages": {
                                  required: "*",
                                  min: "*",
                                },
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.calculateQuantity(row.item)
                                },
                              },
                              model: {
                                value: row.item.credit,
                                callback: function ($$v) {
                                  _vm.$set(row.item, "credit", $$v)
                                },
                                expression: "row.item.credit",
                              },
                            })
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.getCreditValue(row.item)
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                  {
                    key: "cell(action)",
                    fn: function (row) {
                      return [
                        _c("e-button", {
                          attrs: { variant: "primary", icon: "plus" },
                          on: {
                            click: function ($event) {
                              return _vm.addProduct(row.item)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }