<template>
  <b-modal
    id="modal_cold_option"
    :title="$t('Estado dos produtos')"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    hide-footer
    scrollable
    centered
    size="lg"
    @hidden="resetModal"
  >
    <FormulateForm
      ref="formReceiveValue"
      name="formReceiveValue"
      @submit="onConfirm"
    >
      <b-container>
        <b-row class="mt-1">
          <b-col class="d-flex justify-content-center">
            <e-button
              id="modal_cold_option-btn_cold_on_ice"
              class="mr-1"
              variant="primary"
              :text="$t('Embalado no gelo')"
              :text-shortcuts="['CTRL', '1']"
              @click="onConfirm('ColdOnIce')"
            />
            <e-button
              id="modal_cold_option-btn_cold"
              class="mr-1"
              variant="primary"
              :text="$t('Gelado')"
              :text-shortcuts="['CTRL', '2']"
              @click="onConfirm('Cold')"
            />
            <e-button
              id="modal_cold_option-btn_natural"
              variant="primary"
              :text="$t('Natural')"
              :text-shortcuts="['CTRL', '3']"
              @click="onConfirm('Natural')"
            />
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol } from 'bootstrap-vue'
import { keyboardCode, loadingOverlay, formulateHelper, saleDomain } from '@/mixins'
import EButton from '@/views/components/EButton.vue'

export default {
  components: { BModal, BContainer, BRow, BCol, EButton },

  mixins: [keyboardCode, loadingOverlay, formulateHelper, saleDomain],

  props: {},

  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    resetModal() {
      window.removeEventListener('keydown', this.coldOptionShortcut, false)
    },
    showModal() {
      this.$bvModal.show('modal_cold_option')

      setTimeout(() => {
        this.addKeyEvent(this.coldOptionShortcut)
      }, 300)

      return new Promise(resolve => {
        this.$once('button-clicked', coldOption => {
          this.hideModal()
          resolve(coldOption)
        })
      })
    },
    hideModal() {
      this.resetModal()
      this.$bvModal.hide('modal_cold_option')
    },
    async onConfirm(coldOption) {
      this.$emit('button-clicked', coldOption)
    },

    coldOptionShortcut(e) {
      const { COLD_ON_ICE, COLD, NATURAL } = this.coldOptionEnum
      const actions = {
        [this.keyCode['1']]: () => this.onConfirm(COLD_ON_ICE),
        [this.keyCode.num1]: () => this.onConfirm(COLD_ON_ICE),
        [this.keyCode['2']]: () => this.onConfirm(COLD),
        [this.keyCode.num2]: () => this.onConfirm(COLD),
        [this.keyCode['3']]: () => this.onConfirm(NATURAL),
        [this.keyCode.num3]: () => this.onConfirm(NATURAL),
      }

      if (e.ctrlKey && actions[e.code]) {
        e.preventDefault()
        actions[e.code]()
      }
    },
  },
}
</script>
