<template>
  <div class="paybox-layout-container">
    <header
      v-if="withHeader"
      class="header px-1"
    >
      <slot name="header" />
    </header>

    <main class="main bg-light">
      <slot name="main" />
    </main>

    <footer class="footer bg-primary">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    withHeader: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.paybox-layout-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  box-sizing: border-box;
  height: 50px;
  background: var(--light);
  border-bottom: 1px solid var(--theme-color);
}

.main {
  flex-grow: 1;
}

.footer {
  height: 90px;
}
</style>
