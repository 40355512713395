<template>
  <section class="paybox-content-container">
    <section class="left-side">
      <pay-box-purchase-info
        ref="payBoxPurchaseInfo"
        :get-height="getHeight"
        :tab-height="windowSize.tabHeight"
      />
    </section>

    <section class="right-side">
      <pay-box-items-list :get-height="getHeight" />
    </section>

    <section
      v-if="needCashUp"
      class="block-pdv"
    >
      <div class="content-center">
        <b-card-actions no-actions>
          <div>
            <p class="h3 text-danger">
              Caixa Bloqueado
            </p>
          </div>
          <div>
            <p>
              Valor no caixa ultrapassou o limite permitido. <br>
              <b>Por favor, realize a sangria para liberar o caixa</b>
            </p>
          </div>
        </b-card-actions>
      </div>
    </section>


  </section>
</template>

<script>
import { debounce } from 'lodash'
import { app, keyboardCode, loadingOverlay } from '@/mixins'
import { mapActions, mapGetters } from 'vuex'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import PayBoxPurchaseInfo from './components/PayBoxPurchaseInfo.vue'
import PayBoxItemsList from './components/PayBoxItemsList.vue'


export default {
  components: {
    PayBoxPurchaseInfo,
    PayBoxItemsList,
    BCardActions,
  },

  mixins: [app, keyboardCode, loadingOverlay],

  data() {
    return {
      windowSize: {
        height: window.document.body.clientHeight,
        tabHeight: 0,
      },
      hideAside: true,
      asideTimer: null,
      unsubscribe: null,
      helperTypes: {
        cashUp: 'cashUp',
        reinforcement: 'reinforcement',
      },
    }
  },

  computed: {
    ...mapGetters('pages/pdv', ['needCashUp']),

    getHeight() {
      // subtrai height do footer e header
      return this.windowSize.height - 154
    },
  },

  watch: {
    needCashUp(isBlocked) {
      if (isBlocked) {
        this.$refs.payBoxPurchaseInfo.purchaseInfoRemoveEvents()
      } else {
        this.$refs.payBoxPurchaseInfo.purchaseInforAddEvents()
      }
    },
  },

  async mounted() {
    try {
      this.showLoadingOverlay(this.$t('Preparando o caixa'))
      window.addEventListener('resize', this.getDimensions)
      this.getDimensions()
      setTimeout(() => {
        this.windowSize.tabHeight = this.getTabHeight()
      }, 300)
    } catch (error) {
      this.showError({ error })
    } finally {
      this.hideLoadingOverlay()
    }
  },

  created() {
    this.unsubscribe = this.$store.subscribe(async mutation => {
      if (mutation.type.startsWith('pages/pdv/payBox')) {
        await this.$store.dispatch('pages/pdv/payBox/updatePdvSession', {})
      }
    })
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.getDimensions)
  },

  methods: {
    ...mapActions('pages/pdv', ['loadPdvData']),

    // eslint-disable-next-line func-names
    getDimensions: debounce(function () {
      this.windowSize.height = window.document.body.clientHeight
      this.windowSize.tabHeight = this.getTabHeight()
    }, 300),

    getTabHeight() {
      // pego o id gerado pelo bootstrap
      return document.querySelector('#purchaseInfoTab__BV_tab_controls_')?.clientHeight || 0
    },

    async onShowSidebar(type) {
      this.$refs.sidebarHelper.onShowSidebar(type)
    },

    onHideAside(isLeave) {
      clearTimeout(this.asideTimer)
      this.asideTimer = setTimeout(() => {
        this.hideAside = isLeave
      }, 400)
    },
  },
}
</script>

<style lang="scss" scoped>
.paybox-content-container {
  // height: 100vh;
  display: grid;
  grid-template-columns: 46% 54%;
  grid-template-rows: 1fr;

  // TODO corrigir pensando q left e right side estejam dentro de uma grid que não seja do layout
  .left-side {
    grid-area: 1 / 1 / 2 / 2;
    border-right: 4px solid var(--dark);
  }

  .right-side {
    grid-area: 1 / 2 / 2 / 3;
    border-left: 4px solid var(--dark);
  }

  .block-pdv {
    grid-area: 1 / 1 / 2 / 3;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
  }
}
</style>
