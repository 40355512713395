var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "paybox-layout-container" }, [
    _vm.withHeader
      ? _c("header", { staticClass: "header px-1" }, [_vm._t("header")], 2)
      : _vm._e(),
    _c("main", { staticClass: "main bg-light" }, [_vm._t("main")], 2),
    _c("footer", { staticClass: "footer bg-primary" }, [_vm._t("footer")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }