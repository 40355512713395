var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal_delivery-address",
        title: _vm.$t("Endereço para entrega"),
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        size: "lg",
        scrollable: "",
        centered: "",
      },
      on: { hidden: _vm.resetModal },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-row",
                {
                  staticClass:
                    "w-100 d-flex justify-content-between align-items-center",
                },
                [
                  _c("b-col", [
                    _c("p", { staticClass: "mb-0 h3" }, [
                      _vm._v(" " + _vm._s(_vm.$t("Valor da taxa")) + " "),
                    ]),
                    _c("p", { staticClass: "mb-0 h3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("currency")(_vm.modalForm.deliveryTax)
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: {
                          id: "modal_delivery-btn_cancel",
                          variant: "outline-primary",
                          text: _vm.$t("Cancelar"),
                          "text-shortcuts": ["ESC"],
                        },
                        on: { click: _vm.hideModal },
                      }),
                      _c("e-button", {
                        attrs: {
                          id: "modal_delivery-btn_confirm",
                          variant: "primary",
                          text: _vm.$t("Confirmar"),
                        },
                        on: { click: _vm.onConfirm },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "FormulateForm",
        { ref: "formDeliveryAddress", attrs: { name: "formDeliveryAddress" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-card-actions",
                {
                  attrs: {
                    title: _vm.$t("Endereços do cliente"),
                    "no-actions": "",
                  },
                },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "modal_delivery-address_options",
                      type: "radio",
                      "aria-label": _vm.$t("Endereços do cliente"),
                      options: _vm.addressOptions,
                    },
                    on: { input: _vm.changeAddress },
                    model: {
                      value: _vm.modalForm.addressSelected,
                      callback: function ($$v) {
                        _vm.$set(_vm.modalForm, "addressSelected", $$v)
                      },
                      expression: "modalForm.addressSelected",
                    },
                  }),
                ],
                1
              ),
              _c("e-address", {
                ref: "addressComponent",
                on: { "after-find-zipcode": _vm.onFetchDeliveryTax },
                model: {
                  value: _vm.modalForm.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.modalForm, "address", $$v)
                  },
                  expression: "modalForm.address",
                },
              }),
              _c(
                "b-card-actions",
                {
                  attrs: {
                    title: _vm.$t("Observação da entrega"),
                    "no-actions": "",
                  },
                },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "modal_delivery-address_observation",
                      type: "textarea",
                      rows: 4,
                      "aria-label": _vm.$t("Observação"),
                    },
                    model: {
                      value: _vm.modalForm.observation,
                      callback: function ($$v) {
                        _vm.$set(_vm.modalForm, "observation", $$v)
                      },
                      expression: "modalForm.observation",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }