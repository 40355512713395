<template>
  <section>
    <layout-pay-box-section :with-header="false">
      <template #main>
        <b-table-simple
          borderless
          sticky-header
          show-empty
          class="paybox-items-table font-weight-bold mb-0"
          :style="{ minHeight: `${getHeight + 14 - deliveryAddressBoxInfoHeight}px` }"
        >
          <b-thead class="paybox-items-thead">
            <b-tr>
              <template v-for="field in fields">
                <b-th
                  :key="field.key"
                  class="paybox-items-th"
                  :class="field.class"
                  :style="field.thStyle"
                >
                  {{ field.label }}
                </b-th>
              </template>
            </b-tr>
          </b-thead>

          <b-tbody class="paybox-items-tbody">
            <b-tr v-if="items.length === 0">
              <b-td
                colspan="6"
                class="text-center my-auto"
              >
                <div>
                  <p class="h4">
                    Nenhum Produto
                  </p>
                </div>
              </b-td>
            </b-tr>

            <template
              v-for="(item, index) in getItems"
              v-else
            >
              <!-- row com o item principal -->
              <b-tr
                :key="index"
                class="row-item"
              >
                <b-td class="text-center">
                  {{ item.product.kit ? '' : getPositionItem(index) }}
                </b-td>
                <b-td>
                  {{
                    `${item.product.name}${item.choppGiftCardNumber ? ` (${item.choppGiftCardNumber})` : ''
                    }`
                  }}
                </b-td>
                <b-td
                  class="text-center edit-cell"
                  :title="!isChoppGiftCardConversion && !item.choppGiftCardNumber
                    ? $t('Duplo clique para alterar')
                    : ''
                  "
                  @dblclick="showEditAmountCell(index, item)"
                >
                  <FormulateForm
                    v-if="!itemReadOnly && index === indexUpdate"
                    @submit="updateItem(item)"
                  >
                    <FormulateInput
                      :id="`paybox-item-list-amount-${index}`"
                      v-model="formUpdate.amount"
                      name="QTDE."
                      type="text-number"
                      validation="required|min:1"
                    />
                  </FormulateForm>
                  <span v-else> {{ item.amount }} </span>
                </b-td>

                <b-td class="text-left">
                  <template
                    v-if="item.discount > 0 ||
                      item.product.unitDiscount > 0 ||
                      item.discountPriceTable > 0
                    "
                  >
                    <span class="paybox-items-canceled-price">
                      {{ (item.unitValue || item.product.price) | currency(null, 2) }}
                    </span>
                    <br>
                    <span> {{ item.priceInfo.priceWithDiscount | currency(null, 2) }} </span>
                  </template>

                  <template
                    v-else-if="item.promotionId &&
                      (item.product.priceFrom > 0 || item.product.originalPrice > 0)
                    "
                  >
                    <!-- TODO pegar o priceFrom qnd promoção já tiver acabado porém no pedido ainda possuir o id da promoção -->
                    <span class="paybox-items-canceled-price">
                      {{ item.product.priceFrom || item.product.originalPrice | currency(null, 2) }}
                    </span>
                    <br>
                    <span> {{ item.priceInfo.priceWithDiscount | currency(null, 2) }} </span>
                  </template>

                  <template v-else>
                    <span> {{ (item.unitValue || item.product.price) | currency(null, 2) }} </span>
                  </template>
                </b-td>

                <b-td class="text-left">
                  {{ item.subtotal | currency(null, 2) }}
                </b-td>

                <b-td v-if="!itemReadOnly">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="gap: 10px"
                  >

                    <template v-if="index !== indexUpdate">
                      <e-button
                        v-if="!isChoppGiftCardConversion"
                        :id="`paybox-item-list-delete-${index}`"
                        tabindex="-1"
                        size="sm"
                        :variant="getButtonVariant(item)"
                        icon="trash"
                        @click="removeItem(index, item)"
                      />
                    </template>

                    <template v-else>
                      <e-button
                        :id="`paybox-item-list-amount-confirm-update-${index}`"
                        title="Confirmar"
                        size="sm"
                        variant="primary"
                        icon="check-circle"
                        @click="updateItem(item)"
                      />

                      <e-button
                        :id="`paybox-item-list-amount-cancel-update-${index}`"
                        title="Cancelar"
                        size="sm"
                        variant="danger"
                        icon="x-circle"
                        @click="cleanUpdate()"
                      />
                    </template>
                  </div>
                </b-td>
              </b-tr>

              <!-- row com item do KIT -->

              <template v-if="item.product.kit">
                <b-tr
                  v-for="(kitItem, kitIndex) in getKitItems(item.product.kitItems)"
                  :key="`${index}_${kitIndex}`"
                  class="row-item"
                >
                  <b-td class="text-center">
                    {{
                      getPositionItem(index) +
                        (getKitItems(item.product.kitItems).length - 1) -
                        kitIndex
                    }}
                  </b-td>
                  <b-td
                    style="padding-left: 30px"
                    class="d-flex align-items-center"
                  >
                    <p class="mr-1 mb-0">
                      <b-icon icon="arrow-return-right" />
                    </p>
                    <p>
                      {{ kitItem.product.name }}
                    </p>
                  </b-td>
                  <b-td class="text-center">
                    {{ kitItem.amount }}
                  </b-td>
                  <b-td class="text-left">
                    <template v-if="kitItem.discount > 0 || kitItem.product.unitDiscount > 0">
                      <span class="paybox-items-canceled-price">
                        {{ (kitItem.unitValue || kitItem.product.price) | currency(null, 2) }}
                      </span>
                      <br>
                      <span> {{ kitItem.priceInfo.priceWithDiscount | currency(null, 2) }} </span>
                    </template>

                    <template v-else>
                      <span>
                        {{ (kitItem.unitValue || kitItem.product.price) | currency(null, 2) }}
                      </span>
                    </template>
                  </b-td>
                  <b-td />
                  <b-td v-if="!itemReadOnly" />
                </b-tr>
              </template>

              <!-- row com item associado (item comodato) -->

              <template v-if="item.product.lendingItemAssociated">
                <b-tr
                  v-for="(lendingItem, lendingIndex) in item.product.lendingItemAssociated"
                  :key="`${index}_${lendingIndex}_${lendingItem.id}`"
                  class="row-item"
                >
                  <b-td />
                  <b-td style="padding-left: 30px">
                    {{ lendingItem.product.name }}
                  </b-td>
                  <b-td class="text-center">
                    {{ lendingItem.amount }}
                  </b-td>
                  <b-td class="text-left">
                    {{ 0 | currency }}
                  </b-td>
                  <b-td />
                  <b-td v-if="!itemReadOnly" />
                </b-tr>
              </template>
            </template>
          </b-tbody>
        </b-table-simple>

        <div
          v-if="isDelivery"
          class="bg-white w-100 d-flex align-items-center justify-content-start border-top-dark"
          :style="`height: ${deliveryAddressBoxInfoHeight}px`"
        >
          <div
            v-if="false"
            class="mx-1"
          >
            <e-button-icon
              variant="danger"
              icon="XIcon"
              style="padding: 5px"
              @click="onCancelDelivery"
            />
          </div>
          <div class="mx-1">
            <feather-icon
              icon="TruckIcon"
              size="30"
              class="text-primary"
            />
          </div>
          <div>
            <p class="mb-0 font-weight-bold">
              Endereço para entrega: <br>
              {{ deliveryAddressText }}
            </p>
          </div>
        </div>
      </template>

      <template #footer>
        <b-row class="h-100 d-flex align-items-center">
          <b-col
            md="12"
            class="d-flex justify-content-between align-items-center"
          >
            <div
              class="d-flex align-items-baseline"
              style="gap: 10px"
            >
              <div class="footer-value-info">
                <div>
                  <p class="h5 text-right mb-0 text-dark">
                    Subtotal
                  </p>
                </div>
                <div
                  class="d-flex justify-content-end"
                  style="gap: 10px"
                >
                  <e-button-icon
                    v-if="!itemReadOnly"
                    id="paybox-item_list-btn_show_discount"
                    pill
                    :title="$t('Adicionar desconto')"
                    variant="dark"
                    style="padding: 5px"
                    :icon="'PlusIcon'"
                    @click="onShowModalDiscount"
                  />
                  <p class="h5 text-right mb-0 text-dark">
                    Descontos
                  </p>
                </div>
              </div>

              <div class="footer-value-info">
                <p class="h3 text-left mb-0 text-dark">
                  <span class="">
                    {{ getSubtotalSaleWithoutDiscountInfo | currency(null, 2) }}
                  </span>
                </p>
                <p class="h3 text-left mb-0 text-dark">
                  <span class=""> {{ getTotalItemsDiscountInfo | currency(null, 2) }} </span>
                </p>
              </div>
            </div>

            <div v-if="isDelivery">
              <p class="h3 text-left mb-0 text-dark">
                Frete <br>
                <span class="h3 text-dark">
                  {{ deliveryForm.deliveryTax | currency(null, 2) }}
                </span>
              </p>
            </div>

            <div v-if="showComission">
              <div class="footer-value-info">
                <div
                  class="d-flex justify-content-end"
                  style="gap: 10px"
                >
                  <e-button-icon
                    v-if="!itemReadOnly && hasWaiterComissionConfig"
                    id="paybox-item_list-btn_add_comission"
                    pill
                    :title="$t('Adicionar comissão')"
                    variant="dark"
                    style="padding: 5px"
                    :icon="'PlusIcon'"
                    @click="onShowModalComission"
                  />

                  <p class="h5 text-right mb-0 text-dark">
                    Comissão
                  </p>
                </div>
              </div>

              <div class="footer-value-info">
                <p class="h3 text-left mb-0 text-dark">
                  <span class=""> {{ (comission || 0) | currency(null, 2) }} </span>
                </p>
              </div>
            </div>

            <div>
              <p class="h2 text-left mb-0 text-dark">
                Total <br>
                <span class="h1 text-dark"> {{ getTotalSale | currency(null, 2) }} </span>
              </p>
            </div>
          </b-col>
        </b-row>
      </template>
    </layout-pay-box-section>

    <modal-discount
      ref="modalDiscount"
      :initial-value="paymentForm.discount"
      @click-confirm="onAddDiscount"
    />

    <modal-comission
      ref="modalComission"
      @click-confirm="onAddComission"
    />
  </section>
</template>

<script>
import { BTableSimple, BTh, BTd, BTr, BThead, BTbody, BRow, BCol } from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import EButton from '@/views/components/EButton.vue'
import { formulateHelper, keyboardCode, address, payBoxTypes, authorizationRules } from '@/mixins'
import delegablePermissions from '@/utils/delegable-permissions'
import EButtonIcon from '@/views/components/EButtonIcon.vue'
import LayoutPayBoxSection from './LayoutPayBoxSection.vue'
import ModalDiscount from './tabs/components/ModalDiscount.vue'
import ModalComission from './modals/ModalComission.vue'

const getInitialForm = () => ({
  amount: 1,
})

export default {
  components: {
    LayoutPayBoxSection,
    BTableSimple,
    BTh,
    BTd,
    BTr,
    BThead,
    BTbody,
    BRow,
    BCol,
    EButton,
    ModalDiscount,
    EButtonIcon,
    ModalComission,
  },

  mixins: [formulateHelper, keyboardCode, payBoxTypes, authorizationRules],

  props: {
    getHeight: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      indexUpdate: null,
      productUpdate: null,
      formUpdate: getInitialForm(),
      delegateUserData: null,
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
    ...mapState('pages/pdv/payBox', [
      'items',
      'paymentStarted',
      'paymentForm',
      'isDelivery',
      'comission',
      'comissionFee',
      'deliveryForm',
      'tempDelegations',
    ]),

    ...mapGetters('pages/pdv/payBoxConfiguration', ['barConfig']),
    ...mapGetters('pages/pdv/payBox', [
      'getTotalSale',
      'getSubtotalSaleWithoutDiscountInfo',
      'getTotalItemsDiscountInfo',
      'getItems',
      'isConsignedSaleFinish',
      'isBarConsumption',
      'isChoppGiftCardConversion',
      'isOrder',
    ]),

    getComissionRadioOptions() {
      const defaultResult = [{ value: null, label: this.$t('Nenhum') }]
      const result =
        this.barConfig?.storeComissions?.map(c => ({
          value: c.comissionFee,
          label: this.$options.filters.percentage(c.comissionFee),
        })) || []

      return [...result, ...defaultResult]
    },

    hasWaiterComissionConfig() {
      return this.barConfig?.hasWaiterComission
    },

    showComission() {
      return this.isBarConsumption && (this.hasWaiterComissionConfig || this.comission > 0)
    },

    deliveryAddressText() {
      const ad = this.deliveryForm.address
      return ad
        ? address.methods.formatAddress(ad)
        : this.deliveryForm.observation.replace('Endereço de Entrega: ', '')
    },

    deliveryAddressBoxInfoHeight() {
      return this.isDelivery ? 70 : 0
    },

    itemReadOnly() {
      return this.isConsignedSaleFinish
    },

    fields() {
      return [
        {
          label: this.$t('#'),
          key: 'index',
          class: 'text-center',
          thStyle: { width: '50px' },
        },
        {
          label: this.$t('Nome do Produto'),
          key: 'product',
          class: 'text-left',
        },
        {
          label: this.$t('Quant.'),
          key: 'amount',
          class: 'text-center',
          thStyle: { width: '70px' },
        },
        {
          label: this.$t('Preço un.'),
          key: 'price',
          class: 'text-left',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Total'),
          key: 'subtotal',
          class: 'text-left',
          thStyle: { width: '110px' },
        },
        {
          label: this.$t('Ações'),
          key: 'actions',
          class: 'text-center',
          thStyle: { width: '100px' },
          hide: this.itemReadOnly,
        },
      ].filter(a => !a.hide)
    },
  },

  methods: {
    ...mapActions('pages/pdv/payBox', {
      stRemoveItem: 'removeItem',
      stUpdateItem: 'updateItem',
      stSetDiscountPaymentForm: 'setDiscountPaymentForm',
      stSetDeliveryData: 'setDeliveryData',
      stSetAndCalculateComission: 'setAndCalculateComission',
      stValidatePayBoxActionIsBlocked: 'validatePayBoxActionIsBlocked',
      stAddTempDelegation: 'AddTempDelegation',
    }),

    getButtonVariant(item) {
      if (item.localId) {
        return 'danger'
      }
      return this.isOrder ? 'secondary' : 'danger'
    },

    getPositionItem(index) {
      const calcKitItensLenght = i =>
        (i.product.kitItems?.length || 0) +
        (i.product.kitItems?.filter(ki => ki.product?.lendingItemAssociated?.length > 0)
          ?.length || 0)

      const traveledItems = this.getItems.slice(0, index + 1)
      const traveledItemsKit = traveledItems.filter(i => i.product.kit)
      const totalTraveledItems =
        traveledItemsKit.reduce((total, i) => total + calcKitItensLenght(i), 0) +
        traveledItems.length -
        traveledItemsKit.length

      const itemsKit = this.getItems.filter(i => i.product.kit)
      const totalItems =
        itemsKit.reduce((total, i) => total + calcKitItensLenght(i), 0) +
        this.getItems.length -
        itemsKit.length

      return totalItems - totalTraveledItems + 1
    },

    getKitItems(kitItems) {
      if (!kitItems) return []

      const result = kitItems.flatMap(ki => {
        if (ki.product?.lendingItemAssociated?.length > 0) {
          return [ki, ki.product?.lendingItemAssociated[0]]
        }

        return [ki]
      })

      return result
    },

    async showEditAmountCell(index, item) {
      const show = () => {
        this.addKeyEvent(this.listShortcut)
        this.formUpdate = { amount: item.amount }
        this.productUpdate = item
        this.indexUpdate = index
        setTimeout(() => this.focusInput(`#paybox-item-list-amount-${index}`), 100)
      }

      if (item.localId) {
        show()
      }

      if (this.isOrder && item.id) {
        this.showWarning({ message: this.$t('Para alterar quantidade ou excluir produtos de um pedido existente, utilizar a tela "Vendas > Pedidos", do ERP.') })
        return
      }

      if (this.isChoppGiftCardConversion || item.choppGiftCardNumber) {
        return
      }

      const isBlocked = await this.stValidatePayBoxActionIsBlocked({
        action: this.uiPayBoxActionsEnum.PRODUCT_UPDATE,
      })
      if (isBlocked) {
        this.showWarning({ message: this.$t('Ação bloqueada no momento.') })
        return
      }

      if (this.itemReadOnly || item.palmBeerCardNumber) {
        this.showWarning({ message: this.$t('Não é possível alterar a quantidade') })
        return
      }

      if (item.paymentStarted || this.isBarConsumption) {
        try {
          this.delegateUserData = await this.modalAuthRef.auth(
            delegablePermissions.PDV_PRODUCT_EDIT
          )
          show()
        } catch (error) {
          console.error(error)
          if (error.message) {
            this.showWarning({ message: error.message })
          }
        }
      }
      else {
        show()
      }
    },
    cleanUpdate() {
      this.removeKeyEvent(this.listShortcut)
      this.indexUpdate = null
      this.productUpdate = null
      this.delegateUserData = null
      this.formUpdate = getInitialForm()
    },

    async updateItem(item) {
      try {
        const qttyChanged = (this.formUpdate.amount || 0) - (item?.amount || 0)
        const { delegateUserData, productStock, kitItemName } = await this.authAddProductWithNegativeStock(
          item?.product,
          qttyChanged,
          this.getItems,
          this.tempDelegations
        )
        this.delegateUserData = delegateUserData

        if (productStock < 0 && !this.delegateUserData) {
          let message = this.$t('PAYBOX.VIEW.NEGATIVE_STOCK_MESSAGE.UPDATE', { name: item?.product?.name })
          if (kitItemName) message += this.$t('PAYBOX.VIEW.NEGATIVE_STOCK_MESSAGE.KIT_ITEM', { name: kitItemName })

          const confirmAddWithNegativeStock = await this.confirm({
            title: this.$t('PAYBOX.VIEW.NEGATIVE_STOCK_MESSAGE.TITLE'),
            text: message,
          })
          if (!confirmAddWithNegativeStock) {
            this.cleanUpdate()
            return
          }
        }
        if (this.delegateUserData) {
          this.stAddTempDelegation(this.delegateUserData || {})
        }
      } catch (error) {
        if (error.message) {
          this.showWarning({ message: error.message })
        }
        this.cleanUpdate()
        return
      }

      this.stUpdateItem({ payload: { ...item, amount: this.formUpdate.amount } })
      this.cleanUpdate()
    },

    async removeItem(index, item) {
      const remove = async () => {
        await this.stRemoveItem(item)
        this.showSuccess({ message: this.$t('Produto removido') })
      }

      if (this.isOrder && item.id) {
        this.showWarning({ message: this.$t('Para alterar quantidade ou excluir produtos de um pedido existente, utilizar a tela "Vendas > Pedidos", do ERP.') })
        return
      }
      const isBlocked = await this.stValidatePayBoxActionIsBlocked({
        action: this.uiPayBoxActionsEnum.PRODUCT_REMOVE,
      })
      if (isBlocked) {
        this.showWarning({ message: this.$t('Ação bloqueada no momento.') })
        return
      }

      if (item.paymentStarted || this.isBarConsumption || item.localId) {
        try {
          const confirm = await this.confirm({
            message: `Remover o produto: ${item.name}?`,
            focusCancel: true,
          })
          if (!confirm) return
          // eslint-disable-next-line no-unused-vars
          this.delegateUserData = await this.modalAuthRef.auth(
            delegablePermissions.PDV_PRODUCT_DELETE
          )

          await remove()
        } catch (error) {
          console.error(error)
          if (error.message) {
            this.showWarning({ message: error.message })
          }
        }
      }
      else {
        const confirm = await this.confirm({
          message: `Remover o produto: ${item.name}?`,
          focusCancel: true,
        })
        if (confirm) await remove()
      }
    },

    async onShowModalDiscount() {
      const isBlocked = await this.stValidatePayBoxActionIsBlocked({
        action: this.uiPayBoxActionsEnum.SALE_ADD_DISCOUNT,
      })
      if (isBlocked) {
        this.showWarning({ message: this.$t('Ação bloqueada no momento.') })
        return
      }

      if (this.paymentForm.balance <= 0) {
        this.showWarning({ message: this.$t('Não há saldo na venda') })
        return
      }
      this.$refs.modalDiscount.showModal()
    },
    onAddDiscount(form) {
      this.stSetDiscountPaymentForm(form.discount)
    },

    async onShowModalComission() {
      this.$refs.modalComission.showModal({ comissionFee: this.comissionFee })
    },
    onAddComission(comissionForm) {
      this.stSetAndCalculateComission({ comissionFee: comissionForm?.comissionFee })
    },

    async onCancelDelivery() {
      const confirm = await this.confirm({ title: this.$t('Confirme o cancelamento da entrega') })
      if (confirm) this.stSetDeliveryData({ isDelivery: false })
    },

    listShortcut(e) {
      if (e.code === this.keyCode.esc) {
        this.cleanUpdate()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.paybox-items-table {
  width: 100%;
}

.paybox-items-thead {
  height: 50px;

  .paybox-items-th {
    background-color: var(--theme-color) !important;
    color: var(--white) !important;
  }
}

.paybox-items-canceled-price {
  text-decoration: line-through;
  color: var(--danger);
}

.paybox-items-tbody {
  &>tr>td {
    padding: 5px 10px;
    font-size: 1rem;
  }

  &>tr.row-item:nth-child(2n) {
    background-color: rgba(var(--theme-color-rgb), 0.15);
  }

  .edit-cell {
    cursor: pointer;
  }
}

.footer-value-info {
  p {
    line-height: 25px;
  }
}
</style>
