var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-chopp-card-balance",
        title: _vm.$t("Consultar saldo do cartão"),
        "no-close-on-backdrop": "",
        "hide-footer": "",
        size: "lg",
        scrollable: "",
        centered: "",
      },
      on: { hidden: _vm.resetModal },
    },
    [
      _c(
        "b-card-actions",
        { attrs: { "no-actions": "", "show-loading": _vm.fetching } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "customer",
                      value: _vm.customerName,
                      type: "label",
                      label: _vm.$t("Cliente"),
                      filter: "uppercase",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "customer",
                      value: _vm.totalSpent,
                      type: "label",
                      label: _vm.$t("Créditos adquiridos"),
                      filter: "currency",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("FormulateInput", {
                    staticStyle: { "font-size": "30px" },
                    attrs: {
                      id: "customer",
                      value: _vm.balance,
                      type: "label",
                      label: _vm.$t("Saldo atual"),
                      filter: "currency",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-1" },
        [
          _c(
            "b-col",
            { staticClass: "d-flex justify-content-end" },
            [
              _c("e-button", {
                attrs: {
                  variant: "primary",
                  type: "button",
                  text: _vm.$t("Ok"),
                },
                on: {
                  click: function ($event) {
                    return _vm.hideModal()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }